import Vue from "vue";
import App from "./App.vue";

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

import axios from "axios";
Vue.prototype.$axios = axios;

import "./assets/css/reset.css";

import uweb from "vue-uweb";
Vue.use(uweb, "1279427995");
Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount("#app");
