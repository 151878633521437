<template>
  <div class="FI-warp">
    <img class="title-img" src="./../assets/title.png" />
    <div class="ckb-box">
      <img class="ckb-img" src="./../assets/ckb.png" />债券投资控风险<img
        class="ckb-img ml64"
        src="./../assets/ckb.png"
      />股票投资增弹性
    </div>
    <div class="video">
      <video
        id="media"
        poster="./../assets/img_V.png"
        src="./../assets/video/video.mp4"
        controls
      ></video>
    </div>
    <div class="card-box" @click="bug(shibo.fundCode)">
      <span class="buy-btn">一键买入</span>
      <h2>{{ shibo.fundName }}</h2>
      <p class="tag-box">
        <span class="risk-tag"
          ><b
            ><img src="./../assets/ic.png" />{{
              shibo.tag ? shibo.tag.split(",")[0] : ""
            }}</b
          ></span
        ><span
          ><b>{{ shibo.tag ? shibo.tag.split(",")[1] : "" }}</b></span
        >
      </p>
      <div class="income-tips">
        <span>{{ shibo.createText }}</span
        ><span>建议持有时长</span>
      </div>
      <div class="income-value">
        <span class="iv-val ANR">{{ shibo.createValue }}</span
        ><span class="iv-time"
          ><b>{{ shibo.holdTime ? shibo.holdTime.split("|")[1] : "" }}</b
          >{{ shibo.holdTime ? shibo.holdTime.split("|")[2] : "" }}</span
        >
      </div>
      <p class="income-tips2">
        <span><b></b>{{ shibo.tip }}</span>
      </p>
      <h3 class="year-title">
        <span @click.stop="winDaysFn"
          >盈利天数{{ shibo.winDays }}天<img src="./../assets/ic02.png"/></span
        >近一年收益表现
      </h3>
      <div class="myChart-box">
        <b></b>
        <!-- <div id="myChart" :style="{ height: '300px' }"></div> -->
        <div id="myChart" :style="chartstyle"></div>
      </div>
    </div>
    <div class="card-box debt-base">
      <div class="debt-base-con" @click="bug(debtBase[0].fundCode)">
        <h2>{{ debtBase[0].title }}</h2>
        <p class="db-name">{{ debtBase[0].fundName }}</p>
        <p class="db-value ANR">{{ debtBase[0].createValue }}</p>
        <p class="db-time">{{ debtBase[0].createText }}</p>
      </div>
      <div class="debt-base-con" @click="bug(debtBase[1].fundCode)">
        <h2 class="pl30">{{ debtBase[1].title }}</h2>
        <div class="debt-base-two">
          <p class="db-name">{{ debtBase[1].fundName }}</p>
          <p class="db-value ANR">{{ debtBase[1].createValue }}</p>
          <p class="db-time">{{ debtBase[1].createText }}</p>
        </div>
      </div>
      <img class="chart-img" src="./../assets/chart.png" />
      <p class="db-two-detail" @click="secondary()"><span>详解二级债></span></p>
    </div>
    <div class="card-box public-praise" @click="bug(short.fundCode)">
      <h2>{{ short.title }}</h2>
      <div class="ovh">
        <p class="pp-value"><span class="ANR pt20">季季系列</span></p>
        <!-- <span class="ANR">{{short.createValue}}</span>{{short.createText}} -->
        <div class="pp-name">
          <h3>{{ short.fundName }}</h3>
          {{ short.subTitle ? short.subTitle.split("|")[1] : ""
          }}<span>{{ short.subTitle ? short.subTitle.split("|")[2] : "" }}</span
          >{{ short.subTitle ? short.subTitle.split("|")[3] : "" }}
        </div>
      </div>
    </div>
    <div class="card-box public-praise" @click="bug(recom.fundCode)">
      <h2>{{ recom.title }}</h2>
      <div class="ovh">
        <p class="pp-value">
          <span class="ANR">{{ recom.createValue }}</span
          >{{ recom.createText }}
        </p>
        <div class="pp-name">
          <h3>{{ recom.fundName }}</h3>
          <span>{{ recom.subTitle }}</span>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="l" @click="goUrlBanner1">
        <img src="./../assets/banner1.png" alt="" />
      </div>
      <div class="r" @click="goUrlBanner2">
        <img src="./../assets/banner2.png" alt="" />
      </div>
    </div>
    <div class="riskFb">本页面由博时基金管理有限公司提供</div>
    <div class="risk">基金产品由博时基金管理有限公司发行与管理</div>
    <div class="risk">
      中信建投证券作为代销机构不承担产品的投资、兑付和风险管理责任
    </div>
    <div class="risk">
      本页面非任何法律文件，投资前请阅读基金合同、招募说明书
    </div>
    <div class="risk mb40">
      过往业绩不预示未来表现，市场有风险，投资需谨慎
    </div>

    <div class="sy-pop" v-if="isPop">
      <p>过去365天，收益>=0的天数节假日 按收益=0计算</p>
      <div @click="gotIt">我知道了</div>
    </div>
    <div class="mask" v-if="isPop"></div>
  </div>
</template>

<script>
export default {
  name: "FixedIncome",
  props: {
    msg: String
  },
  data() {
    return {
      isPop: false,
      color: "green",
      imgsrc1: require("./../assets/c_bg1.png"),
      imgsrc2: require("./../assets/c_bg2.png"),
      imgsrc: require("./../assets/c_bg.png"),
      offset: [0, 0],
      lineData: [],
      lineAxis: [],
      shibo: {},
      debtBase: [
        {
          title: "",
          fundName: "",
          fundCode: "",
          createText: "",
          createValue: ""
        },
        {
          title: "",
          fundName: "",
          fundCode: "",
          createText: "",
          createValue: ""
        }
      ],
      short: {
        title: "",
        subTitle: "",
        fundName: "",
        fundCode: "",
        createText: "",
        createValue: ""
      },
      recom: {
        title: "",
        subTitle: "",
        fundName: "",
        fundCode: "",
        createText: "",
        createValue: ""
      },
      chartstyle: {
        height: "300px",
        width: "630px"
      }
    };
  },
  mounted() {
    let that = this;
    document.title = "可攻可守固收+";
    this.$axios({
      url:
        "https://bs-activity-server.covoart.com/pomotion-api/citic-managing-money/first",
      method: "get",
      params: {}
    }).then(res => {
      that.lineData = [];
      that.lineAxis = [];
      that.shibo = res.data.data;
      res.data.data.list.forEach(e => {
        that.lineData.push(e.price);
        that.lineAxis.push(e.month);
      });
      that.drawLine();
    });

    this.$axios({
      url:
        "https://bs-activity-server.covoart.com/pomotion-api/citic-managing-money/second",
      method: "get",
      params: {}
    }).then(res => {
      that.debtBase = res.data.data;
    });

    this.$axios({
      url:
        "https://bs-activity-server.covoart.com/pomotion-api/citic-managing-money/third",
      method: "get",
      params: {}
    }).then(res => {
      that.short = res.data.data;
    });

    this.$axios({
      url:
        "https://bs-activity-server.covoart.com/pomotion-api/citic-managing-money/fourth",
      method: "get",
      params: {}
    }).then(res => {
      that.recom = res.data.data;
    });
  },
  methods: {
    secondary() {
      window.location.href = "./secondary.html";
    },
    bug(code) {
      //console.log(code);
      this.$uweb.trackEvent("固收+", "购买" + code, "", 0, "view");
      loadUrl(code, "");
      // var u = navigator.userAgent;
      // // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      // if (isiOS) {
      //   window.location.href =
      //     "https://e.csc108.com/gmjj/main?appType=ios&ofcode=" +
      //     code +
      //     "#product-preview-fund";
      // } else {
      //   window.location.href =
      //     "https://e.csc108.com/gmjj/main?appType=android&ofcode=" +
      //     code +
      //     "#product-preview-fund";
      // }
    },
    winDaysFn() {
      document.body.style.overflow = "hidden";
      this.isPop = true;
      return false;
    },
    gotIt() {
      document.body.removeAttribute("style");
      this.isPop = false;
    },
    off(list) {
      // console.log(list,this.getMaxIndex(list))
      this.getMaxIndex(list);
      if (this.getMaxIndex(list) < 3) {
        return [132, 0];
      } else if (this.getMaxIndex(list) > 8) {
        return [-128, 0];
      } else {
        return [0, 0];
      }
    },
    //获取最大值的下标
    getMaxIndex(arr) {
      var max = arr[0];
      //声明了个变量 保存下标值
      var index = 0;
      for (var i = 0; i < arr.length; i++) {
        if (max < arr[i]) {
          max = arr[i];
          index = i;
        }
      }
      return index;
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      let that = this;
      myChart.setOption({
        // title: { text: "在Vue中使用echarts" },
        tooltip: {},
        xAxis: {
          data: that.lineAxis,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#e7e7e7" // 颜色
            }
          },
          axisLabel: {
            interval: 0,
            fontSize: 20,
            fontFamily: "Microsoft YaHei",
            color: "#999"
          }
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            fontSize: 20,
            fontFamily: "Microsoft YaHei",
            color: "#999"
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#e7e7e7"]
            }
          }
        },
        grid: {
          x: 50,
          y: 50,
          x2: 0,
          y2: 30
        },
        series: [
          {
            name: "销量",
            type: "bar",
            barWidth: 16, //柱图宽度
            data: that.lineData,
            itemStyle: {
              normal: {
                label: {
                  formatter: function(value) {
                    let hightV = that.lineData[that.getMaxIndex(that.lineData)];
                    if (
                      that.getMaxIndex(that.lineData) < 3 &&
                      value.data == hightV
                    ) {
                      return "{a|" + "买1万元，最高月收益" + hightV + "元}";
                    } else if (
                      that.getMaxIndex(that.lineData) > 8 &&
                      value.data == hightV
                    ) {
                      return "{b|" + "买1万元，最高月收益" + hightV + "元}";
                    } else if (value.data == hightV) {
                      return "{c|" + "买1万元，最高月收益" + hightV + "元}";
                    } else {
                      return "";
                    }
                  },
                  show: true,
                  position: "top",
                  offset: that.off(that.lineData),
                  rich: {
                    a: {
                      align: "center",
                      height: "60",
                      fontSize: "20",
                      color: "#fff",
                      backgroundColor: {
                        image: that.imgsrc1
                      }
                    },
                    b: {
                      align: "center",
                      height: "60",
                      fontSize: "20",
                      color: "#fff",
                      backgroundColor: {
                        image: that.imgsrc2
                      }
                    },
                    c: {
                      align: "center",
                      height: "60",
                      fontSize: "20",
                      color: "#fff",
                      backgroundColor: {
                        image: that.imgsrc
                      }
                    }
                  }
                },
                color: function(item) {
                  if (item.data > 0) {
                    return "#ff3d00";
                  } else {
                    return "#00a23b";
                  }
                }
              }
            }
            // markPoint:{
            //         data:[
            //             {type:'max',name:'最大值',symbol:'roundRect',lable:'买1万元，最高月收益313.93元'},
            //             // {type:'min',name:'最小值',symbol:'arrow'}
            //         ]
            //     },
          }
        ]
      });

      let w = document.body.clientWidth;
      console.log(w);
      let sc = w / 750;
      console.log(sc);
      let chartstyle = {
        height: (300 * w) / 750 + "px",
        transform: "scale(" + sc + ")",
        "transform-origin": "left top"
      };
      this.chartstyle = chartstyle;
    },
    goUrlBanner1() {
      // this.$uweb.trackEvent("固收+","banner1", "", 0, "view");
      // location.href = 'http://dajiuge.com.cn/test/20191029-boshi-book/bookDetail1.php'
      window.location.href = "./video1.html";
    },
    goUrlBanner2() {
      window.location.href = "./video2.html";
      // this.$uweb.trackEvent("固收+","banner2", "", 0, "view");
      // location.href = 'http://dajiuge.com.cn/test/20191029-boshi-book/bookDetail2.php'
    }
  }
};

// var tradeAccount;

// function gotoLoginViewAsLoginType(loginType, backMethodName) {
//     if (browser.versions.android) {
//         KDS_Native.gotoLoginViewAsLoginType(loginType, backMethodName);
//     }
//     else if (browser.versions.ios) {
//         location.href = "KDS_Native://gotoLoginViewAsLoginType:" + loginType + ":" + backMethodName;
//     }
// }
// function getUserData(userDataType, backMethodName) {
// 	if (browser.versions.android) {
// 		KDS_Native.getUserData(userDataType, backMethodName);
// 	} else if (browser.versions.ios) {
// 		loadURL("KDS_Native://getUserData:" + userDataType + ":"
// 				+ backMethodName);
// 	}
// }
// var get_trade_account = function(callBack) {
// 	var share = callBack.get_trade_account;
// 	CURRENT_TRADE_ACCOUNT = share[0].CURRENT_TRADE_ACCOUNT;
// 	tradeAccount = CURRENT_TRADE_ACCOUNT;
// 	window.localStorage.setItem('tradeAccount', tradeAccount);
// }
var browser = {
  versions: (function() {
    var u = navigator.userAgent,
      app = navigator.appVersion;
    console.log(app);
    return {
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //
    };
  })(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase()
};

function loadUrl(jjdm, jjztbm) {
  console.log(jjdm, jjztbm);
  var url;
  if (jjztbm == "c") {
    if (browser.versions.android) {
      url =
        "https://e.csc108.com/gmjj/main?appType=android&ofcode=" +
        jjdm +
        "#product-preview-fund-new";
    } else if (browser.versions.ios) {
      url =
        "https://e.csc108.com/gmjj/main?appType=ios&ofcode=" +
        jjdm +
        "#product-preview-fund-new";
    }
  } else {
    if (browser.versions.android) {
      url =
        "https://e.csc108.com/gmjj/main?appType=android&ofcode=" +
        jjdm +
        "#product-preview-fund";
    } else if (browser.versions.ios) {
      url =
        "https://e.csc108.com/gmjj/main?appType=ios&ofcode=" +
        jjdm +
        "#product-preview-fund";
    }
  }
  openThirdPartyWebInSubPage("0", "KDS_TICKET_NO_LOGINED_JSSWITCH", url, null);
}
// function loadUrl1(proCode,instType){

// 	var url;
// 	if (browser.versions.android) {
// 		url = "https://e.csc108.com/bmall/main?appType=android&ofcode="+proCode+"&appVer=null&instType="+instType+"#product-preview-bank";
// 	} else if (browser.versions.ios) {
// 		url = "https://e.csc108.com/bmall/main?appType=ios&ofcode="+proCode+"&appVer=null&instType="+instType+"#product-preview-bank";
// 	}
// 	openThirdPartyWebInSubPage('0', 'KDS_TICKET_NO_LOGINED_JSSWITCH', url,null);
// }
// function loadUrl2(proCode){

// 	var url;
// 	if (browser.versions.android) {
// 		url = "https://e.csc108.com/bmall/main?appType=android&proCode="+proCode+"#gsb-product-info";
// 	} else if (browser.versions.ios) {
// 		url = "https://e.csc108.com/bmall/main?appType=ios&proCode="+proCode+"#gsb-product-info";
// 	}
// 	openThirdPartyWebInSubPage('0', 'KDS_TICKET_NO_LOGINED_JSSWITCH', url,null);
// }
function openThirdPartyWebInSubPage(
  isCloseCurrent,
  functionCode,
  loadUrl,
  userDataJson
) {
  console.log(
    [
      "openThirdPartyWebInSubPage",
      isCloseCurrent,
      functionCode,
      loadUrl,
      userDataJson
    ].join(", ")
  );
  if (browser.versions.android) {
    KDS_Native.openThirdPartyWebInSubPage(
      isCloseCurrent,
      functionCode,
      loadUrl,
      userDataJson
    );
  } else if (browser.versions.ios) {
    loadURL(
      "KDS_Native://openThirdPartyWebInSubPage:" +
        [isCloseCurrent, functionCode, loadUrl, userDataJson].join(":")
    );
  }
}

/**
 * Javascript语言
 * 通知iPhone UIWebView 加载url对应的资源
 * @param url
 */
function loadURL(url) {
  var iFrame;
  iFrame = document.createElement("iframe");
  iFrame.setAttribute("src", url);
  iFrame.setAttribute("style", "display:none;");
  iFrame.setAttribute("height", "0px");
  iFrame.setAttribute("width", "0px");
  iFrame.setAttribute("frameborder", "0");
  document.body.appendChild(iFrame);
  // 发起请求后这个iFrame就没用了，所以把它从dom上移除掉
  iFrame.parentNode.removeChild(iFrame);
  iFrame = null;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// @import url('./FixedIncome.scss');
@import "./FixedIncome.scss";
</style>
